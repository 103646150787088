/**企业认证接口**/
import request from './request'

export default {
	//企业类型枚举
	companytypeenum (data) {
	  return request({
	    url: '/mall-platform-goods/web/enterprise/type/enum',
	    method: 'post',
	    data
	  })
	},
	//企业经营类型枚举
	companybusinessenum (data) {
	  return request({
	    url: '/mall-platform-goods/web/enterprise/business/enum',
	    method: 'post',
	    data
	  })
	},
	//企业规模类型枚举
	companyscaleenum (data) {
	  return request({
	    url: '/mall-platform-goods/web/enterprise/scale/enum',
	    method: 'post',
	    data
	  })
	},
	//企业认证信息创建
	companyadd (data) {
	  return request({
	    url: '/mall-platform-goods/web/enterprise/add',
	    method: 'post',
	    data
	  })
	},
	//企业认证信息修改
	companyedit (data) {
	  return request({
	    url: '/mall-platform-goods/web/enterprise/edit',
	    method: 'post',
	    data
	  })
	},
	//企业认证信息查询
	companyinfo (data) {
	  return request({
	    url: '/mall-platform-goods/web/enterprise/get',
	    method: 'post',
	    data
	  })
	},
}