import Cookies from 'js-cookie'

const TokenKey = 'token'

export function getToken() {
	return Cookies.get(TokenKey)
}

export function setToken(token) {
	return Cookies.set(TokenKey, token)
}

export function removeToken() {
	return Cookies.remove(TokenKey)
}

export function getUser() {
	return Cookies.get('user') ? JSON.parse(Cookies.get('user')) : null
}
export function getPayInfo() {
	return Cookies.get('payinfo') ? JSON.parse(Cookies.get('payinfo')) : null
}

export function setUser(user) {
	return Cookies.set('user', user)
}
export function setPayInfo(payinfo) {
	return Cookies.set('payinfo', payinfo)
}


export function removeUser() {
	return Cookies.remove('user')
}