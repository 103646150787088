/**公共接口**/
import request from './request'

export default {
	//获取图形验证码
	captchaCodes (query) {
	  return request({
	    url: '/mall-platform-order/web/captcha',
	    method: 'get',
		params: query
	  })
	},
	//账号密码登录
	Loginpwd (data) {
	  return request({
	    url: "/mall-platform-order/web/login/pwd",
	    method: 'post',
	    data
	  })
	},
	//手机验证码登录
	mobileCodesLogin (data) {
	  return request({
	    url: "/mall-platform-order/web/login/mobile",
	    method: 'post',
	    data
	  })
	},
	//发送登录验证码
	smsLoginCodes (data) {
	  return request({
	    url: "/mall-platform-order/web/login/sms",
	    method: 'post',
	    data
	  })
	},
	//发送注册验证码
	smsRegisterCodes (data) {
	  return request({
	    url: "/mall-platform-order/web/register/sms",
	    method: 'post',
	    data
	  })
	},
	//会员注册
	Registerapply (data) {
	  return request({
	    url: "/mall-platform-order/web/register/apply",
	    method: 'post',
	    data
	  })
	},
	//重置密码
	resetapplypsd (data) {
	  return request({
	    url: "/mall-platform-order/web/login/reset",
	    method: 'post',
	    data
	  })
	},
	//修改密码
	membereditpwd (data) {
	  return request({
	    url: "/mall-platform-order/web/member/edit/pwd",
	    method: 'post',
	    data
	  })
	},
	//会员信息查询
	webmemberInfo (data) {
	  return request({
	    url: "/mall-platform-order/web/member/get",
	    method: 'post',
	    data
	  })
	},
	//会员店铺切换
	memberchoicestore (data) {
	  return request({
	    url: "/mall-platform-order/web/member/choice/store",
	    method: 'post',
	    data
	  })
	},
	//会员信息头像修改
	webmembereditavatar (data) {
	  return request({
	    url: "/mall-platform-order/web/member/edit",
	    method: 'post',
	    data
	  })
	},
	//oss阿里云文件上传
	webosspolicy (data) {
	  return request({
	    url: "/mall-platform-order/web/ali/oss/policy",
	    method: 'post',
	    data
	  })
	},
	//图片识别营业执照
	webocrenterprise (data) {
	  return request({
	    url: "/mall-platform-order/web/ocr/enterprise",
	    method: 'post',
	    data
	  })
	},
	//图片识别身份证件
	webocridCard (data) {
	  return request({
	    url: "/mall-platform-order/web/ocr/idCard",
	    method: 'post',
	    data
	  })
	},
	//图片识别银行卡
	webocrbank (data) {
	  return request({
	    url: "/mall-platform-order/opt/ocr/bank",
	    method: 'post',
	    data
	  })
	},
	//平台中心帮助中心
	webinfohelplist (data) {
	  return request({
	    url: '/info-service/web/info/list',
	    method: 'post',
	    data
	  })
	},
	// 字典
	dictQueryList (data) {
	  return request({
	    url: '/info-service/web/info/dict/queryList',
	    method: 'post',
	    data
	  })
	},
	// 新闻/帮助中心 分类列表
	classifyList (data) {
	  return request({
	    url: '/info-service/web/info/classify/list',
	    method: 'post',
	    data
	  })
	},
	// 新闻/帮助中心 列表
	newsHelpInfoList (data) {
	  return request({
	    url: '/info-service/web/info/list',
	    method: 'post',
	    data
	  })
	},
	// 新闻/帮助中心 左侧列表
	newsHelpInfoTreeList (data) {
	  return request({
	    url: '/info-service/web/info/classify/treeList',
	    method: 'post',
	    data
	  })
	},
	// 新闻/帮助中心 详情
	newsHelpInfoGet (data) {
	  return request({
	    url: '/info-service/web/info/get',
	    method: 'post',
	    data
	  })
	},
	// 招采分页列表
	noticeList (data) {
	  return request({
	    url: '/info-service/web/info/notice/list',
	    method: 'post',
	    data
	  })
	},
	// 招采详情
	noticeGet (data) {
	  return request({
	    url: '/info-service/web/info/notice/get',
	    method: 'post',
	    data
	  })
	},
	// 省市区三级联动
	provincesList (data) {
	  return request({
	    url: '/info-service/web/info/provinces/list',
	    method: 'post',
	    data
	  })
	},
}