import Vue from 'vue'
import Vuex from 'vuex'
import { setItem, getItem, removeItem } from "@/libs/storage";

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		centerPath: getItem('shopCenterPath') || {
			activePath: '/OrderIndex/EnterpriseCertification',
			TabIndex: 0, // 0买家中心 1卖家中心
			myOrderInt: 0, // 我的订单tab值
		},
		mallcenterPath: getItem('mallCenterPath') || {
			activePath: '/OrderIndex/EnterpriseCertification',
			TabIndex: 1, // 0买家中心 1卖家中心
			myMallOrderInt: 0, // 我的订单tab值
		},
		aftercenterPath: getItem('afterCenterPath') || {
			activePath: '/OrderIndex/EnterpriseCertification',
			TabIndex: 1, // 0买家中心 1卖家中心
			myAfterOrderInt: 4, // 我的订单tab值
		},
	},
	mutations: {
		SET_CENTER_PATH(state, value) {
			state.centerPath = value;
			setItem('shopCenterPath', value)
		},
		SET_MALLCENTER_PATH(state, value) {
			state.mallcenterPath = value;
			setItem('mallCenterPath', value)
		},
		SET_AFTERCENTER_PATH(state, value) {
			state.aftercenterPath = value;
			setItem('afterCenterPath', value)
		},
	},
	actions: {
		setCenterPath({commit}, data) {
			commit('SET_CENTER_PATH', data)
		},
		setMallCenterPath({commit}, data) {
			commit('SET_MALLCENTER_PATH', data)
		},
		setAfterCenterPath({commit}, data) {
			commit('SET_AFTERCENTER_PATH', data)
		},
	},
	getters: {
		getCenterPath(state) {
			return state.centerPath
		},
		getMallCenterPath(state) {
			return state.mallcenterPath
		},
		getAfterCenterPath(state) {
			return state.aftercenterPath
		}
	},
	// modules: {

	// }
})