import order from "./order.js"
import shop from "./shop.js"  //商品与店铺相关接口
import company from "./company.js"  //企业认证接口
import shopfreight from "./shopfreight.js" //商品管理与运费相关接口
import publicapi from "./public.js" //公共接口

export default { 
	order,
	shop,
	company,
	shopfreight,
	publicapi,
}
