import $moment from "@/libs/moment.min.js"
import AxiosJs from "axios";
import * as apipublic from "@/api/public"; //引入公共接口
export default {
	// 关键字 字体高亮
	joinBg(str, key) {
		if(key){
			var reg = new RegExp((`(${key})`), "gm");
			var replace = '<span style="color:#FF5046;font-weight: bold;">$1</span>';
			return str?str.replace(reg, replace):"";
		}else{
			return str
		}
	},
	// 计算两个时间差 dateBegin 开始时间 status 默认都显示 1只显示天 cseconds传值为分|秒
	timeFn(dateBegin, dateEnd, status, cseconds) {
		if (!dateBegin) {
			dateBegin = new Date();
		}
		if (!dateEnd) {
			dateEnd = new Date();
		}
		var dateDiff = new Date(dateEnd.toString().replace(/-/g, '/')).getTime() - new Date(dateBegin.toString()
			.replace(/-/g, '/')).getTime(); //时间差的毫秒数
		var dayDiff = Math.floor(dateDiff / (24 * 3600 * 1000)); //计算出相差天数
	
		var leave1 = dateDiff % (24 * 3600 * 1000) //计算天数后剩余的毫秒数
		var hours = Math.floor(leave1 / (3600 * 1000)) //计算出小时数
		//计算相差分钟数
		var leave2 = leave1 % (3600 * 1000) //计算小时数后剩余的毫秒数
		var minutes = Math.floor(leave2 / (60 * 1000)) //计算相差分钟数
		//计算相差秒数
		var leave3 = leave2 % (60 * 1000) //计算分钟数后剩余的毫秒数
		var seconds = cseconds?cseconds:Math.round(leave3 / 1000)
		// var leave4=leave3%(60*1000)   //计算分钟数后剩余的毫秒数
		// var minseconds=Math.round(leave4/1000)
		var str = '';
		if (dayDiff) {
			str += Math.abs(dayDiff) + "天";
		}
		if (hours) {
			str += Math.abs(hours) + "时";
		}
		if (minutes) {
			str += Math.abs(minutes) + "分"
		}
		// 精确到秒
		if (seconds && status == 4) {
			str += Math.abs(seconds) + "秒"
		}
		if (str == '') {
			str += Math.abs(dayDiff);
		}
	
		//var timeFn = + + ; //+seconds+" 秒"+minseconds+"毫秒";
		if (status == 1) {//天
			str = Math.abs(dayDiff)
		}
		if (status == 2) { // 返回相差时
			str = Math.abs(hours)
		}
		if (status == 3) { // 返回天 不带文字
			str = Math.abs(dayDiff)
		}
		// status==5 不足1分返回秒 不足1小时返回分 不足24小时返回小时 大于24小时返回天
		if (status == 5) {
			str = {
				num: 0,
				unit: ''
			}
			if (dayDiff) {
				str.num = dayDiff
				str.unit = '天'
			} else if (hours && dayDiff == 0) {
				str.num = hours
				str.unit = '小时'
			} else if (minutes && dayDiff == 0 && hours == 0) {
				str.num = minutes
				str.unit = '分钟'
			} else if (seconds && minutes == 0 && dayDiff == 0 && hours == 0) {
				str.num = seconds
				str.unit = '秒'
			} else if (!seconds && minutes == 0 && dayDiff == 0 && hours == 0) {
				str.num = 0
				str.unit = '秒'
			}
		}
		// status==6 不足1分返回秒 不足1小时返回分 不足24小时返回小时 大于24小时返回天
		if (status == 6) {
			str = ''
			if (dayDiff > 0) {
				str += dayDiff + '天'
			} else if (hours != 0 && dayDiff == 0) {
				str += hours + '小时'
			} else if (minutes != 0 && dayDiff == 0 && hours == 0) {
				str += minutes + '分钟'
			} else if (seconds != 0 && minutes == 0 && dayDiff == 0 && hours == 0) {
				str += seconds + '秒'
			}
		}
		// 将秒转为时分秒
		if (status == 7) {
			str = ''
			let hours = Math.floor(cseconds / 3600)
			let minutes = Math.floor((cseconds % 3600) / 60)
			let remainingSeconds = cseconds % 60
			if (hours != 0) {
				str += hours + '小时'
			} else if (minutes != 0 && hours == 0) {
				str += minutes + '分钟' + remainingSeconds + '秒'
			} else if (remainingSeconds >= 0 && minutes == 0 && hours == 0) {
				str += remainingSeconds + '秒'
			}
		}
		// 传开始时间+秒 返回时间
		if (status == 8) {
			str = $moment(dateBegin).add((cseconds/60), 'minutes').format('YYYY-MM-DD HH:mm:ss')
		} 
		// 将分转为天时分
		if (status == 9) {
			str = ''
			cseconds = parseInt(cseconds) //取整
			let duration = $moment.duration(cseconds, 'minutes');
			let days = duration.days();
			let hours = duration.hours();
			let minutes = duration.minutes();
			if (days != 0) {
				str += days + '天' + hours + '时' + minutes + '分'
			} else if (hours != 0 && days == 0) {
				str += hours + '时' + minutes + '分'
			} else if (minutes >= 0 && days == 0 && hours == 0) {
				str += minutes + '分'
			}
		}
		// 计算时间相差秒数 返回数值
		if (status == 10) {
			str = $moment(dateEnd).diff(dateBegin, 'seconds')
		}
		return str;
	},
	// 返回 传分钟数 返回整分|时|天
	GetTimes(Minutes){
		let obj = ''
		if(Minutes < 60){
			obj = {
				Int: Minutes.toFixed(0),
				unit: '分钟'
			}
		}
		if(Minutes > 60 && Minutes < (60 * 24)){
			obj = {
				Int: (Minutes / 60).toFixed(1),
				unit: '小时'
			}
		}
		if(Minutes > (60 * 24)){
			obj = {
				Int: (Minutes / (60 * 24)).toFixed(0),
				unit: '天'
			}
		}
		return obj
	},
	// 去除HTML
	removeHTMLTag(str) {
		str = str.replace(/<\/?[^>]*>/g, ''); //去除HTML tag
		str = str.replace(/[ | ]*\n/g, '\n'); //去除行尾空白
		//str = str.replace(/\n[\s| | ]*\r/g,'\n'); //去除多余空行
		str = str.replace(/&nbsp;/ig, ''); //去掉&nbsp;
		str = str.replace(/&lt;/ig, '');
		str = str.replace(/&gt;/ig, '');
		str = str.replace(/[div|\/div]/g, '');
		str = str.replace(/&lt;[ -~]*&gt;/ig, ''); //去掉替换后的<>标签
		return str;
	},
	// raba格式转为带#颜色
	GetHexify(color) {
	    var values = color
	      .replace(/rgba?\(/, '')
	      .replace(/\)/, '')
	      .replace(/[\s+]/g, '')
	      .split(',');
	    var a = parseFloat(values[3] || 1),
	      r = Math.floor(a * parseInt(values[0]) + (1 - a) * 255),
	      g = Math.floor(a * parseInt(values[1]) + (1 - a) * 255),
	      b = Math.floor(a * parseInt(values[2]) + (1 - a) * 255);
	    return "#" +
	      ("0" + r.toString(16)).slice(-2) +
	      ("0" + g.toString(16)).slice(-2) +
	      ("0" + b.toString(16)).slice(-2);
	},
	//锚点跳转
	GOPosition(item) {
		document.getElementById(item) .scrollIntoView({ behavior: 'smooth' })
	},
	generateRandomString(number){
		var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		var randomString = "";
		for (var i = 0; i < number; i++) {
		    var randomIndex = Math.floor(Math.random() * chars.length);
		    randomString += chars.charAt(randomIndex);
		 }
		return randomString;
	},
	getUUID() {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
			return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
		})
	},
	Uploadfile(param) {
		let file = param.file; // 得到文件的内容
		this.$api.publicapi.webosspolicy({}).then(response => {
			if (response.code == 200) {
				let policyData = response.data;
				let ossUrl = policyData.host; //填写自己OSS服务器的地址
				let accessUrl = policyData.dir + this.getUUID(); //设置上传的访问路径
				let sendData = new FormData(); // 上传文件的data参数
				sendData.append('OSSAccessKeyId', policyData.access_id);
				sendData.append('policy', policyData.policy);
				sendData.append('Signature', policyData.signature);
				sendData.append('keys', policyData.dir);
				sendData.append('key', accessUrl); //上传的文件路径
				sendData.append('success_action_status', 200); // 指定返回的状态码
				sendData.append('type', 'image/jpeg');
				sendData.append('file', file);
				AxiosJs.post(ossUrl, sendData).then((res) => {
					return 'https://'+policyData.domain + '/' + accessUrl; //获得到的url需要将其存数据库中
				})
			}
		})
	},
}