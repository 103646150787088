/**商品管理与运费相关接口**/
import request from './request'
export default {
	//店铺商品管理 选项模板创建
	 webtemplateadd (data){
		return request({
		  url: '/mall-platform-goods/web/selection/template/add/compose',
		  method: 'post',
		  data
		}) 
	 },
	//店铺商品管理 选项模板修改
	webtemplateedit (data) {
		return request({
		  url: '/mall-platform-goods/web/selection/template/edit/compose',
		  method: 'post',
		  data
		})
	},
	//店铺商品管理 选项模板查询
	webtemplateinfo (data) {
		return request({
		  url: '/mall-platform-goods/web/selection/template/get/compose',
		  method: 'post',
		  data
		})
	},
	//店铺商品管理 选项分页查询
	webtemplatepagelist (data) {
	  return request({
	    url: '/mall-platform-goods/web/selection/template/page/list',
	    method: 'post',
	    data
	  })
	},
	//店铺商品管理 选项模板启用/停用
	webtemplateenable (data) {
	  return request({
	    url: '/mall-platform-goods/web/selection/template/edit',
	    method: 'post',
	    data
	  })
	},
	//店铺商品管理 选项模板删除
	webtemplatedel (data) {
	  return request({
	    url: '/mall-platform-goods/web/selection/template/del',
	    method: 'post',
	    data
	  })
	},
	//店铺商品管理商品列表
	merchantgoodslist (data) {
	  return request({
	    url: '/mall-platform-goods/web/merchant/goods/page/list',
	    method: 'post',
	    data
	  })
	},
	//店铺商品管理商品新增
	merchantgoodsadd (data) {
	  return request({
	    url: "/mall-platform-goods/web/merchant/goods/add/compose",
	    method: 'post',
	    data
	  })
	},
	//店铺商品管理商品修改
	merchantgoodsedit (data) {
	  return request({
	    url: "/mall-platform-goods/web/merchant/goods/edit/compose",
	    method: 'post',
	    data
	  })
	},
	//店铺商品管理商品详情组合
	merchantgoodsDetails (data) {
	  return request({
	    url: "/mall-platform-goods/web/merchant/goods/get/compose",
	    method: 'post',
	    data
	  })
	},
	//店铺商品管理商品修改-启用/排序
	merchantgoodsSort (data) {
	  return request({
	    url: "/mall-platform-goods/web/merchant/goods/edit",
	    method: 'post',
	    data
	  })
	},
	//店铺商品管理商品删除
	merchantgoodsdel (data) {
	  return request({
	    url: "/mall-platform-goods/web/merchant/goods/del",
	    method: 'post',
	    data
	  })
	},
	//店铺运费模块 运费模版列表
	freighttemplatelist (data) {
	  return request({
	    url: "/mall-platform-goods/web/freight/template/list",
	    method: 'post',
	    data
	  })
	},
	//店铺运费模块 运费模版分页列表
	freighttemplatepagelist (data) {
	  return request({
	    url: "/mall-platform-goods/web/freight/template/page/list",
	    method: 'post',
	    data
	  })
	},
	//店铺运费模块 运费模版 详情
	freighttemplateDetails (data) {
	  return request({
	    url: "/mall-platform-goods/web/freight/template/get",
	    method: 'post',
	    data
	  })
	},
	//店铺运费模块 运费模版 新增
	freighttemplateadd (data) {
	  return request({
	    url: "/mall-platform-goods/web/freight/template/add",
	    method: 'post',
	    data
	  })
	},
	//店铺运费模块 运费模版 修改
	freighttemplateedit (data) {
	  return request({
	    url: "/mall-platform-goods/web/freight/template/edit",
	    method: 'post',
	    data
	  })
	},
	//店铺运费模块 运费模版 删除
	freighttemplatedel (data) {
	  return request({
	    url: "/mall-platform-goods/web/freight/template/del",
	    method: 'post',
	    data
	  })
	},
	//店铺商品管理库存管理-查询
	goodsstockList (data) {
	  return request({
	    url: "/mall-platform-goods/web/merchant/goods/get/stock/compose",
	    method: 'post',
	    data
	  })
	},
	//店铺商品管理库存管理-修改
	goodsstockedit (data) {
	  return request({
	    url: "mall-platform-goods/web/merchant/goods/edit/stock/compose",
	    method: 'post',
	    data
	  })
	},
	
}