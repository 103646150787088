import Vue from "vue";
import VueRouter from "vue-router";
import publicRouter from "./modules/public";

Vue.use(VueRouter);

// 路由重复问题
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err);
};

const routes = [
  ...publicRouter,
  {
    path: "/",
    name: "index",
    meta: {
      keepalive: true,
      navindex: "1",
    },
    children: [],
  },
];

const router = new VueRouter({
  mode: "history", // 不带#号，改为 history 模式
  routes,
});

export default router;
