import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import App from './App.vue'
import router from './router'
import store from "./store";
import './assets/less/main.less'

Vue.prototype.$CodeIconUrl = "https://digital.cnwltz.com" //"https://gateway.adwend.com"; // 测试地址 图片验证码  

// 接口调用
import api from "@/api/api.js"
Vue.prototype.$api = api;

// 引入common
import common from "@/libs/common"
Vue.prototype.$common = common;

// 引入moment
import moment from "@/libs/moment.min.js"
Vue.prototype.$moment = moment;

Vue.use(ElementUI)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
