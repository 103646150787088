//  公用路由  首页与登录
export default [
  {
    path: '/',
    name: 'index',
    meta: {
      keepalive: true,
      navindex: '1'
    },
    component: () => import('@/views/index.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        meta: {
          keepalive: true,
          navindex: '1'
        },
        component: () => import('@/views/Home/index.vue'),
      },
	  {
	    path: '/ShopList',
	    name: 'HomeShopList',
	    meta: {
	      keepalive: true,
	      navindex: '1'
	    },
	    component: () => import('@/views/Home/shopList.vue'),
	  },
	  {
	    path: '/storeIndex',
	    name: 'storeIndex',
	    meta: {
	      keepalive: true,
	      navindex: '1'
	    },
	    component: () => import('@/views/Home/storeIndex.vue'),
	  },
	  {
	    path: '/shopDetails',
	    name: 'shopDetails',
	    meta: {
	      keepalive: true,
	      navindex: '1'
	    },
	    component: () => import('@/views/Home/shopDetails.vue'),
	  },
	  {
	    path: '/OrderIndex',
	    name: 'OrderIndex',
	    meta: {
	      keepalive: true,
	      navindex: '2'
	    },
	    component: () => import('@/views/Order/index.vue'),
	    children: [
	  			{
	  				path: '/OrderIndex/EnterpriseCertification',
	  				name: 'OrderIndex',
	  				meta: {
	  				  keepalive: true,
	  				  navindex: '2'
	  				},
	  				component: () => import('@/views/Order/EnterpriseCertification.vue'), //企业认证
	  			},
	  			{
	  				path: '/OrderIndex/ReceivingAddress',
	  				name: 'OrderIndex',
	  				meta: {
	  				  keepalive: true,
	  				  navindex: '2'
	  				},
	  				component: () => import('@/views/Order/ReceivingAddress.vue'), //收货地址
	  			},
	  			{
	  				path: '/OrderIndex/ApplyStore',
	  				name: 'OrderIndex',
	  				meta: {
	  				  keepalive: true,
	  				  navindex: '2'
	  				},
	  				component: () => import('@/views/Order/ApplyStore.vue'), //申请开店
	  			},
	  			{
	  				path: '/OrderIndex/MyOrder',
	  				name: 'OrderIndex',
	  				meta: {
	  				  keepalive: true,
	  				  navindex: '2'
	  				},
	  				component: () => import('@/views/Order/MyOrder.vue'), //我的订单
	  			},
	  			{
	  				path: '/OrderIndex/MyOrderDetails',
	  				name: 'OrderIndex',
	  				meta: {
	  				  keepalive: true,
	  				  navindex: '2'
	  				},
	  				component: () => import('@/views/Order/MyOrderDetails.vue'), //我的订单详情
	  			},
				{
					path: '/OrderIndex/StoreOrder',
					name: 'OrderIndex',
					meta: {
					  keepalive: true,
					  navindex: '2'
					},
					component: () => import('@/views/Order/Seller/StoreOrder.vue'), //卖家-我的订单
				},
				{
					path: '/OrderIndex/StoreOrderDetails',
					name: 'OrderIndex',
					meta: {
					  keepalive: true,
					  navindex: '2'
					},
					component: () => import('@/views/Order/Seller/StoreOrderDetails.vue'), //卖家-我的订单详情
				},
	  			{
	  				path: '/OrderIndex/MyOrderMaterialFlow',
	  				name: 'OrderIndex',
	  				meta: {
	  				  keepalive: true,
	  				  navindex: '2'
	  				},
	  				component: () => import('@/views/Order/MyOrderMaterialFlow.vue'), //我的订单详情-物流信息
	  			},
	  			{
	  				path: '/OrderIndex/AfterSaleOrder',
	  				name: 'OrderIndex',
	  				meta: {
	  				  keepalive: true,
	  				  navindex: '2'
	  				},
	  				component: () => import('@/views/Order/AfterSaleOrder.vue'), //售后订单
	  			},
				{
					path: '/OrderIndex/StoreAfterOrder',
					name: 'OrderIndex',
					meta: {
					  keepalive: true,
					  navindex: '2'
					},
					component: () => import('@/views/Order/Seller/StoreAfterOrder.vue'), //卖家售后订单
				},
	  			{
	  				path: '/OrderIndex/MyCollection',
	  				name: 'OrderIndex',
	  				meta: {
	  				  keepalive: true,
	  				  navindex: '2'
	  				},
	  				component: () => import('@/views/Order/MyCollection.vue'), //我的收藏
	  			},
	  			{
	  				path: '/OrderIndex/TalentRecruitment',
	  				name: 'OrderIndex',
	  				meta: {
	  				  keepalive: true,
	  				  navindex: '2'
	  				},
	  				component: () => import('@/views/Order/TalentRecruitment.vue'), //人才招聘
	  			},
	  			{
	  				path: '/OrderIndex/ResumeEdit',
	  				name: 'OrderIndex',
	  				meta: {
	  				  keepalive: true,
	  				  navindex: '2'
	  				},
	  				component: () => import('@/views/Order/ResumeEdit.vue'), //简历编辑
	  			},
				{
					path: '/OrderIndex/ShopList',
					name: 'OrderIndex',
					meta: {
					  keepalive: true,
					  navindex: '2'
					},
					component: () => import('@/views/Order/Seller/ShopList.vue'), //店铺管理-店铺列表
				},
				{
					path: '/OrderIndex/ShopEdit',
					name: 'OrderIndex',
					meta: {
					  keepalive: true,
					  navindex: '2'
					},
					component: () => import('@/views/Order/Seller/ShopEdit.vue'), //店铺管理-店铺编辑
				},
				{
					path: '/OrderIndex/CertificateList',
					name: 'OrderIndex',
					meta: {
					  keepalive: true,
					  navindex: '2'
					},
					component: () => import('@/views/Order/Seller/CertificateList.vue'), //证书管理-证书列表
				},
				{
					path: '/OrderIndex/ProductList',
					name: 'OrderIndex',
					meta: {
					  keepalive: true,
					  navindex: '2'
					},
					component: () => import('@/views/Order/Seller/ProductList.vue'), //商品管理-商品列表
				},
				{
					path: '/OrderIndex/ProductEdit',
					name: 'OrderIndex',
					meta: {
					  keepalive: true,
					  navindex: '2'
					},
					component: () => import('@/views/Order/Seller/ProductEdit.vue'), //商品管理-商品编辑
				},
				{
					path: '/OrderIndex/FreightList',
					name: 'OrderIndex',
					meta: {
					  keepalive: true,
					  navindex: '2'
					},
					component: () => import('@/views/Order/Seller/FreightList.vue'), //运费模板管理-运费模板列表
				},
				{
					path: '/OrderIndex/FreightEdit',
					name: 'OrderIndex',
					meta: {
					  keepalive: true,
					  navindex: '2'
					},
					component: () => import('@/views/Order/Seller/FreightEdit.vue'), //运费模板管理-运费模板编辑
				},
				{
					path: '/OrderIndex/ContractList',
					name: 'OrderIndex',
					meta: {
					  keepalive: true,
					  navindex: '2'
					},
					component: () => import('@/views/Order/Seller/ContractList.vue'), //合同管理-合同列表
				},
				{
					path: '/OrderIndex/ContractEdit',
					name: 'OrderIndex',
					meta: {
					  keepalive: true,
					  navindex: '2'
					},
					component: () => import('@/views/Order/Seller/ContractEdit.vue'), //合同管理-合同列表
				}
	  		]
	  },
	  {
	    path: '/helpCenter',
	    name: 'helpCenter',
	    meta: {
	      keepalive: true,
	      navindex: '3'
	    },
	    component: () => import('@/views/helpCenter/index.vue'),
	    children: []
	  },
	  {
	    path: '/CashierDesk',
	    name: 'CashierDesk',
	    meta: {
	      keepalive: true,
	      navindex: '4'
	    },
	    component: () => import('@/views/CashierDesk/index.vue'), //收银台
	    children: []
	  },
	  {
	    path: '/CashierDesk/Pay',
	    name: 'CashierDeskPay',
	    meta: {
	      keepalive: true,
	      navindex: '4'
	    },
	    component: () => import('@/views/CashierDesk/Pay.vue'), //收银台-支付
	    children: []
	  },
	  {
	    path: '/UserInfo',
	    name: 'UserInfo',
	    meta: {
	      keepalive: true,
	      navindex: '5'
	    },
	    component: () => import('@/views/User/UserInfo.vue'), //个人信息管理
	    children: []
	  },
	  {
	    path: '/UserChangepsd',
	    name: 'UserChangepsd',
	    meta: {
	      keepalive: true,
	      navindex: '6'
	    },
	    component: () => import('@/views/User/UserChangepsd.vue'), //修改密码
	    children: []
	  },
	  {
	    path: '/CallBidsList',
	    name: 'CallBidsList',
	    meta: {
	      keepalive: true,
	      navindex: '8'
	    },
	    component: () => import('@/views/Announcement/CallBidsList.vue'), //招标公告-列表
	    children: []
	  },
	  {
	    path: '/CallBidsDetails',
	    name: 'CallBidsDetails',
	    meta: {
	      keepalive: true,
	      navindex: '8'
	    },
	    component: () => import('@/views/Announcement/CallBidsDetails.vue'), //招标公告-详情
	    children: []
	  },
	  {
	    path: '/PlatformList',
	    name: 'PlatformList',
	    meta: {
	      keepalive: true,
	      navindex: '8'
	    },
	    component: () => import('@/views/Announcement/PlatformList.vue'), //平台公告-列表
	    children: []
	  },
	  {
	    path: '/PlatformDetails',
	    name: 'PlatformDetails',
	    meta: {
	      keepalive: true,
	      navindex: '8'
	    },
	    component: () => import('@/views/Announcement/PlatformDetails.vue'), //平台公告-详情
	    children: []
	  },
    ]
  },
  {
    path: '/CartList',
    name: 'CartList',
    meta: {
      title: "我的采购单",
      keepalive: true,
	   navindex: '8'
    },
    component: () => import('@/views/CartList/index.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      title: "登录",
      keepalive: true
    },
    component: () => import('@/views/login/login.vue') 
  },
];
