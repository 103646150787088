import router from "@/router";
import axios from "axios";
import CryptoJS from 'crypto-js';
import { getToken } from './auth'
import { Message } from 'element-ui'
//创建axios实例
const service = axios.create({
  // 公共接口
  baseURL: "https://digital.cnwltz.com", //线上地址
  // baseURL: "https://gateway.adwend.com", //测试地址
  // 超时时间 单位是ms，这里设置了10s的超时时间
  timeout: 100 * 1000,
});

const generateRandomString = (number) =>{
	var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	var randomString = "";
	for (var i = 0; i < number; i++) {
	    var randomIndex = Math.floor(Math.random() * chars.length);
	    randomString += chars.charAt(randomIndex);
	 }
	return randomString;
}
//签名函数
const getSignature = ()=>{
	const timestamp = Math.floor(Date.now() / 1000); // 获取当前时间戳(秒)
	const nonce_str = generateRandomString(8)
	const open_code ='1801712626959454164834'
	const api_key ='35cc5b7d4321772d6a9d032600637315'
	// 定义要加密的字符串
	let str = open_code+timestamp+nonce_str+api_key
	// 将字符串转换成UTF8编码格式
	str = CryptoJS.enc.Utf8.parse(str);
	// 进行MD5加密
	let signature =CryptoJS.MD5(str).toString().toLowerCase()
	return {
	timestamp,
	'nonce-str':nonce_str,
	'open-code' :open_code,
	signature,
	}
}


// 2.请求拦截器
service.interceptors.request.use(
  config => {
	  // 获取签名
	  const signatureData =getSignature()
	  // 将签名信息放入请求头部
	  config.headers = {
		  ...signatureData,
		  'member-token':getToken()?getToken():''
	  }
	  // console.log('config',config)
    // if (getToken()) {
    //   // 判断是否有headers
    //   if (config && config.headers) {
    //     config.headers['Authorization'] = 'Bearer ' + getToken()
    //   }
    // }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// 3.响应拦截器
service.interceptors.response.use(
  (response) => {
    const res = response.data
	// console.log('response',response)
	// console.log('res',res)
    if (response.status === 500) {
      Message({
        type: 'error',
        message: '接口错误，错误代码500',
        duration: 2000
      })

      return
    }
    if (response.status === 404) {
      Message({
        type: 'error',
        message: '接口错误，错误代码404',
        duration: 2000
      })
      return
    }
    // if (response.status === 401) {
    //   // Message({
    //   // 	type: 'error',
    //   // 	message: '登录失效请重新登录',
    //   // 	duration: 2000
    //   // })
    //   router.push('/login')
    //   return
    // }
    if (response.status === 200) {
      return res
    }
  },
  (error) => {
	  console.log('error',error)
    // if (error.response.status === 401) {
    //   router.push("/login")
    //   // Message({
    //   // 	type: 'error',
    //   // 	message: '登录失效请重新登录',
    //   // 	duration: 2000
    //   // })
    //   return
    // }
    // if (error.response.status === 500) {
    //   Message({
    //     type: 'error',
    //     message: '接口错误，错误代码500',
    //     duration: 2000
    //   })
    //   return
    // }
    Message({
      type: 'error',
      message: error.response,
      duration: 2000
    })
    return Promise.resolve(error.response);
  }
);

//4.导入文件
export default service;
