<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
	export default{
		name: 'app',
		data() {
		  return {}
		},
		created() {},
		methods: {},
	}
</script>
<style>
	/* 当页面宽度小于750px 手机适屏 */
	@media screen and (max-width:750px) {
		#app {
			min-width: 1280px;
			padding: 0 20px;
			background: #F6F6F6;
			box-sizing: border-box;
		}
	    body{
	      min-width: 1280px;
		  padding: 0 20px;
		  background: #F6F6F6;
		  box-sizing: border-box;
	    }
		html{
			margin:0;
			padding: 0;
			background: #F6F6F6;
			box-sizing: border-box;
		}
	}
	/* .el-message {
	  top: 50vh !important;
	} */
	.el-dialog__headerbtn {
	  top: 15px;
	}
	/* .el-popper {
	  margin-top: 0 !important;
	} */
	/* 202101141129 start */
	.el-radio__input.is-checked .el-radio__inner {
		border-color: #FF5F03;
		background: #FF5F03;
	}
	.el-radio__input.is-checked+.el-radio__label {
		color: #FF5F03;
	}
	.el-select-dropdown__item.selected{
		color: #FF5F03;
	}
	.el-pagination.is-background .el-pager li:not(.disabled).active {
		background-color: #FF5F03;
	}
	.el-pagination.is-background .el-pager li.number:hover {
		color: #FF5F03;
	}
	.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path {
		color: #FF5F03;
	}
	.el-checkbox__input.is-checked+.el-checkbox__label{
		color: #FF5F03;
	}
	.el-checkbox__input.is-checked .el-checkbox__inner{
		background-color: #FF5F03;
		border-color: #FF5F03;
	}
	.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
		border-color: #FF5F03;
		background: #FF5F03;
	}
	.el-button--text:hover {
		color: #FF5F03;
	}
	/**
	* 解决el-input设置类型为number时，中文输入法光标上移问题
	**/
	.el-input__inner{
	  line-height: 1px!important;
	}
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button{
		    -webkit-appearance: none;
			margin:0;
	}
	.el-popper{
		z-index: 99999 !important;
	}
	/* 202101141129 end */
</style>