// 订单相关接口
import request from './request'
export default {
	//快递公司
	logisticsenum(data){
		return request({
		  url: "/mall-platform-order/enum/logistics",
		  method: 'post',
		  data
		})
	},
	//订单买家-创建订单
	ordercreate(data){
		return request({
		  url: "/mall-platform-order/web/member/order/create",
		  method: 'post',
		  data
		})
	},
	//订单买家-修改支付凭证
	ordereditreceipt(data){
		return request({
		  url: "/mall-platform-order/web/member/order/edit/receipt",
		  method: 'post',
		  data
		})
	},
	//订单买家-订单支付创建签名
	orderpaysign(data){
		return request({
		  url: "/mall-platform-order/web/member/order/pay/sign",
		  method: 'post',
		  data
		})
	},
	//订单买家-订单支付状态查询
	orderpaystatus(data){
		return request({
		  url: "/mall-platform-order/web/member/order/pay/get",
		  method: 'post',
		  data
		})
	},
	//订单买家-订单列表
	orderpagelist(data){
		return request({
		  url: "/mall-platform-order/web/member/order/page/list",
		  method: 'post',
		  data
		})
	},
	//订单买家-订单详情
	orderDetails(data){
		return request({
		  url: "/mall-platform-order/web/member/order/get",
		  method: 'post',
		  data
		})
	},
	//订单买家-订单退款申请
	orderrefundapply(data){
		return request({
		  url: "/mall-platform-order/web/member/order/refund/apply",
		  method: 'post',
		  data
		})
	},
	//订单买家-订单退款申请取消
	orderrefundapplycancel(data){
		return request({
		  url: "/mall-platform-order/web/member/order/refund/apply/cancel",
		  method: 'post',
		  data
		})
	},
	//订单买家-确认收货/已完成
	memberorderfinish(data){
		return request({
		  url: "/mall-platform-order/web/member/order/finish",
		  method: 'post',
		  data
		})
	},
	//订单买家-待支付删除
	memberorderdel(data){
		return request({
		  url: "/mall-platform-order/web/member/order/del",
		  method: 'post',
		  data
		})
	},
	//订单卖家-订单列表
	merchantorderpagelist(data){
		return request({
		  url: "/mall-platform-order/web/merchant/order/page/list",
		  method: 'post',
		  data
		})
	},
	//订单卖家-订单详情
	merchantorderdetails(data){
		return request({
		  url: "/mall-platform-order/web/merchant/order/get",
		  method: 'post',
		  data
		})
	},
	//订单卖家-订单发货
	merchantorderdelivery(data){
		return request({
		  url: "/mall-platform-order/web/merchant/order/delivery",
		  method: 'post',
		  data
		})
	},
	//订单卖家-订单核销完成
	merchantorderfinish(data){
		return request({
		  url: "/mall-platform-order/web/merchant/order/finish",
		  method: 'post',
		  data
		})
	},
	//订单卖家-订单退款
	merchantorderrefund(data){
		return request({
		  url: "/mall-platform-order/web/merchant/order/refund",
		  method: 'post',
		  data
		})
	},
	//订单卖家-订单拒绝退款
	merchantorderrefundcancel(data){
		return request({
		  url: "/mall-platform-order/web/merchant/order/refund/cancel",
		  method: 'post',
		  data
		})
	},
	//订单卖家-订单支付状态/金额修改
	merchantorderedit(data){
		return request({
		  url: "/mall-platform-order/web/merchant/order/edit",
		  method: 'post',
		  data
		})
	},
	//订单卖家-删除
	merchantorderdel(data){
		return request({
		  url: "/mall-platform-order/web/merchant/order/del",
		  method: 'post',
		  data
		})
	},
	//订单卖家-订单明细导出
	merchantorderexport(data){
		return request({
		  url: "/mall-platform-order/web/merchant/order/export",
		  method: 'post',
		  data
		})
	},
	//采购单添加-批量规格
	goodscartaddbatch(data){
		return request({
		  url: "/mall-platform-goods/web/cart/add/batch",
		  method: 'post',
		  data
		})
	},
	//采购单添加-单个规格
	goodscartadd(data){
		return request({
		  url: "/mall-platform-goods/web/cart/add",
		  method: 'post',
		  data
		})
	},
	//采购单-修改
	goodscartchange(data){
		return request({
		  url: "/mall-platform-goods/web/cart/change",
		  method: 'post',
		  data
		})
	},
	//采购单-删除
	goodscartremove(data){
		return request({
		  url: "/mall-platform-goods/web/cart/remove",
		  method: 'post',
		  data
		})
	},
	//采购单-清空
	goodscartclear(data){
		return request({
		  url: "/mall-platform-goods/web/cart/clear",
		  method: 'post',
		  data
		})
	},
	//采购单-列表
	goodscartlist(data){
		return request({
		  url: "/mall-platform-goods/web/cart/list",
		  method: 'post',
		  data
		})
	},
	//采购单-店铺列表
	goodscartstorelist(data){
		return request({
		  url: "/mall-platform-goods/web/cart/store/list",
		  method: 'post',
		  data
		})
	},
	//采购单-下单
	goodscartorder(data){
		return request({
		  url: "/mall-platform-goods/web/cart/order",
		  method: 'post',
		  data
		})
	},
	//收货地址详情
	memberaddressDetails (data) {
	  return request({
	    url: "/mall-platform-order/web/member/address/get",
	    method: 'post',
	    data
	  })
	},
	//收货地址列表
	memberaddressList (data) {
	  return request({
	    url: "/mall-platform-order/web/member/address/list",
	    method: 'post',
	    data
	  })
	},
	//收货地址分页列表
	memberaddresspageList (data) {
	  return request({
	    url: "/mall-platform-order/web/member/address/page/list",
	    method: 'post',
	    data
	  })
	},
	//收货地址添加
	memberaddressAdd (data) {
	  return request({
	    url: "/mall-platform-order/web/member/address/add",
	    method: 'post',
	    data
	  })
	},
	//收货地址修改
	memberaddressEdit (data) {
	  return request({
	    url: "/mall-platform-order/web/member/address/edit",
	    method: 'post',
	    data
	  })
	},
	//收货地址删除
	memberaddressDel (data) {
	  return request({
	    url: "/mall-platform-order/web/member/address/del",
	    method: 'post',
	    data
	  })
	},
	addressTreeList (data) {
	  return request({
	    url: "/info-service/web/info/provinces/treeList",
	    method: 'post',
	    data
	  })
	},
	// 证书列表
	certificateList (data) {
	  return request({
	    url: "/mall-platform-goods/web/certificate/list",
	    method: 'post',
	    data
	  })
	},
	// 证书查询
	certificateGet (data) {
	  return request({
	    url: "/mall-platform-goods/web/certificate/get",
	    method: 'post',
	    data
	  })
	},
	// 证书创建
	certificateAdd (data) {
	  return request({
	    url: "/mall-platform-goods/web/certificate/add",
	    method: 'post',
	    data
	  })
	},
	// 证书修改
	certificateEdit (data) {
	  return request({
	    url: "/mall-platform-goods/web/certificate/edit",
	    method: 'post',
	    data
	  })
	},
	// 证书删除
	certificateDel (data) {
	  return request({
	    url: "/mall-platform-goods/web/certificate/del",
	    method: 'post',
	    data
	  })
	},
	// 查询门店
	merchantStoreGet (data) {
	  return request({
	    url: "/mall-platform-goods/web/merchant/store/get",
	    method: 'post',
	    data
	  })
	},
	// 创建门店
	merchantStoreAdd (data) {
	  return request({
	    url: "/mall-platform-goods/web/merchant/store/add",
	    method: 'post',
	    data
	  })
	},
	// 修改门店
	merchantStoreEdit (data) {
	  return request({
	    url: "/mall-platform-goods/web/merchant/store/edit",
	    method: 'post',
	    data
	  })
	},
	// 门店分页列表
	merchantStorePageList (data) {
	  return request({
	    url: "/mall-platform-goods/web/merchant/store/page/list",
	    method: 'post',
	    data
	  })
	},
	// 人才招聘列表
	talentList (data) {
	  return request({
	    url: "/info-service/web/info/talent/list",
	    method: 'post',
	    data
	  })
	},
	// 人才招聘详情
	talentGet (data) {
	  return request({
	    url: "/info-service/web/info/talent/get",
	    method: 'post',
	    data
	  })
	},
	// 人才招聘编辑
	talentEdit (data) {
	  return request({
	    url: "/info-service/web/info/talent/edit",
	    method: 'post',
	    data
	  })
	},
	// 人才招聘添加
	talentAdd (data) {
	  return request({
	    url: "/info-service/web/info/talent/add",
	    method: 'post',
	    data
	  })
	},
	// 人才招聘删除
	talentDeleted (data) {
	  return request({
	    url: "/info-service/admin/talent/delete",
	    method: 'post',
	    data
	  })
	},
	// 人才招聘-简历详情
	resumeGet (data) {
	  return request({
	    url: "/info-service/web/info/talent/resume/get",
	    method: 'post',
	    data
	  })
	},
	// 合同分页列表
	contractPageList (data) {
	  return request({
	    url: "/mall-platform-goods/web/contract/page/list",
	    method: 'post',
	    data
	  })
	},
	// 合同添加
	contractAdd (data) {
	  return request({
	    url: "/mall-platform-goods/web/contract/add",
	    method: 'post',
	    data
	  })
	},
	// 合同编辑
	contractEdit (data) {
	  return request({
	    url: "/mall-platform-goods/web/contract/edit",
	    method: 'post',
	    data
	  })
	},
	// 合同详情
	contractGet (data) {
	  return request({
	    url: "/mall-platform-goods/web/contract/get",
	    method: 'post',
	    data
	  })
	},
	// 合同删除
	contractDel (data) {
	  return request({
	    url: "/mall-platform-goods/web/contract/del",
	    method: 'post',
	    data
	  })
	},
	// gateway.adwend.com
}