/**商品与店铺相关接口**/
import request from './request'

export default {
	//商品分类列表
	webclassifylist (data) {
	  return request({
	    url: '/mall-platform-goods/web/classify/list',
	    method: 'post',
	    data
	  })
	},
	//首页banner广告位
	bannernoticelist (data) {
	  return request({
	    url: '/info-service/web/info/config/notice/list',
	    method: 'post',
	    data
	  })
	},
	//首页推荐分类广告位及商品
	webclassifygoodslist (data) {
	  return request({
	    url: '/mall-platform-goods/web/classify/goods/list',
	    method: 'post',
	    data
	  })
	},
	//商品列表
	webgoodslist (data) {
	  return request({
	    url: '/mall-platform-goods/web/goods/list',
	    method: 'post',
	    data
	  })
	},
	//商品详情
	goodsDetails (data) {
	  return request({
	    url: '/mall-platform-goods/web/goods/get',
	    method: 'post',
	    data
	  })
	},
	//店铺列表
	webstorelist (data) {
	  return request({
	    url: '/mall-platform-goods/web/store/list',
	    method: 'post',
	    data
	  })
	},
	//店铺详情
	storeDetails (data) {
	  return request({
	    url: '/mall-platform-goods/web/store/get',
	    method: 'post',
	    data
	  })
	},
	//添加收藏
	addcollect (data) {
	  return request({
	    url: '/mall-platform-goods/web/collect/add',
	    method: 'post',
	    data
	  })
	},
	//删除收藏
	delcollect (data) {
	  return request({
	    url: '/mall-platform-goods/web/collect/del',
	    method: 'post',
	    data
	  })
	},
	//查询收藏列表
	collectlist (data) {
	  return request({
	    url: '/mall-platform-goods/web/collect/list',
	    method: 'post',
	    data
	  })
	},
	//根据收藏编码查询是否收藏
	collectCodes (data) {
	  return request({
	    url: 'mall-platform-goods/web/collect/codes',
	    method: 'post',
	    data
	  })
	},
}